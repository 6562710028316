<template>
  <div>
    <PageTitle v-bind:title="'Search Lemmata'" class="mt-2 mb-4" />

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col>
          <b-form-input
            placeholder="Pattern (lemma form, internal/external occurrence)"
            v-model="pattern"
            @keyup.enter="searchLemmata"
            size="sm"
          ></b-form-input>
        </b-col>
        <b-col>
          <b-button
            :disabled="pattern.length == 0"
            @click="searchLemmata"
            size="sm"
            class="mt-custom-form"
          >
            Search
            <b-icon icon="search" aria-hidden="true"></b-icon>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-table
            v-if="config.editable"
            class="table"
            ref="table"
            :fields="fields"
            :items="items"
            :fixed=true
            :hover=true
            :small=false
            :bordered=false
            :borderless=false
            :head-variant="headv">
            <template #cell(lemma)="data">
              <a
                :href="`#/lemma/${data.item.lemmaId}`">
                <span>
                  {{ data.item.lemma }}
                </span>
              </a>
            </template>
            <template #cell(article)="data">
              <div>
                <span>
                  <a
                    v-if="data.item.articleId"
                    :href="`#/article/${data.item.articleId}`">
                    <!-- <button
                      type="button"
                      class="btn btn-light btn-sm mr-1 float-right">
                      <svg
                        viewBox="0 0 16 16"
                        width="1em"
                        height="1em"
                        focusable="false"
                        role="img"
                        aria-label="book"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="bi-book mr-1 b-icon bi"> -->
                        <span>
                          {{ data.item.article }}
                        </span>
                      <!-- </svg>
                    </button> -->
                  </a>
                </span>
              </div>
            </template>
          </b-table>
          <LegamDataTablesClientSide
            v-else
            v-bind="$data"
            ref="DataTablesComponent"
            :key="key"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  .btn-light {
    text-align: center;
    vertical-align: center;
  }
  * >>> .tdAction {
    width: 100px;
  }
</style>

<script>
  const entityLookup = {
    1: 'internal',
    2: 'lemma',
    3: 'external',
  }

  const dictionaryLookup = {
    1: 'TEAF',
    2: 'DEAG',
  }

  import { mapState } from 'vuex'
  import PageTitle from '@/components/PageTitle.vue'
  import config from '@/config.js'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'

  export default {
    name: 'LemmaSearch',
    data(){
      return {
        fields: [
          { key: "match", sortable: true, searchable: true},
          { key: "entity", sortable: true, searchable: true},
          { key: "lemma", sortable: true, searchable: true},
          { key: "article", sortable: true, searchable: true},
          { key: "language", sortable: true, searchable: true},
          { key: "dictionary", sortable: true, searchable: true},
        ],
        columns: [
          { title: "Match", field: "match", sortable: true, searchable: true },
          { title: "Entity", field: "entity", sortable: true, searchable: true, transformData: function(row){
            return entityLookup[row.entity]
          } },
          { title: "Lemma", field: "lemma", sortable: true, searchable: true, transformData: (row) => {
            this.$store.dispatch('loadSimpleLemma', row.lemma).then(
              (lemmaObj) => {
                if (document.querySelector(`a[data-lemma-id="${row.lemma}"]`)) {
                  document.querySelectorAll(`a[data-lemma-id="${row.lemma}"]`).forEach(element => element.innerHTML = lemmaObj.lemmaForm)
                }
              })
            return `<a href="#/lemma/${row.lemma}" data-lemma-id="${row.lemma}"></a>`
          } },
          { title: "Article", field: "article", sortable: true, searchable: true, transformData: (row) => {
            let retVal = "-"
            if (row.article) {
              this.$store.dispatch('loadSimpleArticle', row.article).then(
                (articleObj) => {
                  if (document.querySelector(`a[data-article-id="${row.article}"]`)) {
                    document.querySelectorAll(`a[data-article-id="${row.article}"]`).forEach(element => element.innerHTML = articleObj.mainLemma.lemmaForm)
                  }
                })
              retVal = `<a href="#/article/${row.article}" data-article-id="${row.article}"></a>`;
              }
              return retVal;
          } },
          { title: "Language", field: "language", sortable: true, searchable: true },
          { title: "Dictionary", field: "dictionary", sortable: true, searchable: true, transformData: function(row){
            return dictionaryLookup[row.dictionary]
          }  },
        ],
        url: "/lemmas_search/datatables",
        items: [],
        key: 1,
        // searchableByColumn: true,
        config: config,
        pattern: "",
        headv: "light"
      }
    },
    components: {
      PageTitle,
      LegamDataTablesClientSide,
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings
      }),
      // tableAdditionalData(){
      //   return {
      //     'biblabel': this.userSettings.biblabels,
      //     'biblabelSuppressed': this.userSettings.biblabelsSuppressed,
      //   }
      // }
    },
    methods: {
      searchLemmata(){
        if (this.config.editable) {
          this.$store.dispatch('fuzzySearchLemmata', {pattern: this.pattern}).then((response) => {
            this.items = response.data.map( x => x[0] ).sort( (a, b) => a.lemma > b.lemma );
            //console.log(this.items = response.data.map( x => x[0] ).sort( (a, b) => a.lemma > b.lemma ));
          })
        }
        else {
          this.$refs.DataTablesComponent.setFilterExternal('match', this.pattern);
        }
      }
    },
    // watch: {
    //   "userSettings"(){
    //     this.key++
    //   }
    // }
  }
</script>
